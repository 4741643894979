<template>
<Layout el="hm fixed" id="page">
    <header el="header">
        <List el="xsm p-lg">
            <img src="../assets/logo/alphaleaf_logo_color.svg" id="logo" alt="logo" el="logo" />
        </List>
    </header>
    <main el="main">
        <List el="md p-lg" class="page">
            <Text el="h2">Select Payment Option</Text>
            <hr el/>
            <List el="column-fill lg" class="paymentList">
            <List el="xsm p-md" style="--border: 3px solid #2facee;">
                <Text el="h4">Pay at your own pace</Text>
                <div>
                <div el="status-success">Recommended - $11,000</div>
                </div>
                <ul el>
                <li>Get <b>$1,000 off</b> when you pay with Affirm</li>
                <li>Choose between 4-36 months!</li>
                <li>No hidden/late fees</li>
                </ul>
                <a href="https://alphaleaf-pay.burkedesigns.workers.dev/set-affirm" target="_blank" el="btn">Pay with Affirm</a>
            </List>
            <List el="xsm p-md">
                <Text el="h4">Pay in full</Text>
                <div>
                <div el="status-info">Pay $10,500</div>
                </div>
                <ul el>
                <li>Save <b>$1,500</b> when you pay in full</li>
                <li>No hidden fees</li>
                </ul>
                <a href="https://buy.stripe.com/6oE16y9n50Wd4XCaEH" target="_blank" el="btn">Pay $10,500</a>
            </List>
            <List el="xsm p-md">
                <Text el="h4">Basic Plan</Text>
                <div>
                <div el="status-pending">Pay $12,000</div>
                </div>
                <ul el>
                <li>Pay $400 per month during the course</li>
                <li>Spend 2 months landing your new job</li>
                <li>Then pay $2,000 per month for 5 months</li>
                <li>Late fees apply</li>
                </ul>
                <a href="https://buy.stripe.com/aEU5mO42L0Wdeyc004" target="_blank" el="btn">Select plan</a>
            </List>
            </List>
            <Text el="p">Once a payment is made, please wait for an administrator to verify your account.</Text>
            <div style="text-align:center;">
                <Btn @click="$store.commit('signOut')">Sign Out</Btn>
            </div>
        </List>
    </main>
  </Layout>
  
</template>
<style lang="scss" scoped>
#logo{
    height:70px;
    margin:auto;
}
#page{
    background-color:#f1f1f1;
}
.page{
    max-width:1200px;
    margin:auto;
}
.paymentList > *{
  background-color:white;
  border-radius:12px;
}
</style>